import PropTypes from 'prop-types'
import React from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import { injectIntl } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'

import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import Paper from '@material-ui/core/Paper'
import Tooltip from '@material-ui/core/Tooltip'
import { withStyles } from '@material-ui/core/styles'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'
import PersonIcon from '@material-ui/icons/Person'
import ReplayIcon from '@material-ui/icons/Replay'
import SearchIcon from '@material-ui/icons/Search'

import DataChip from 'components/DataChip'
import DateLabel from 'components/DateLabel'
import { client, logError } from 'utils/http'
import reactBootstrapTable from 'utils/reactBootstrapTable'

import NotificationsDetail from '../components/NotificationsDetail'
import NotificationsFilter from '../components/NotificationsFilter'
import NotificationsGlobalActions from '../components/NotificationsGlobalActions'
import messages from '../messages'
import { getNotificationsUrl, getNotificationDetailsUrl } from '../urls'
import { composeLocalQuery, getAdaptedNotificationsData } from '../utils'

const styles = {
  dialog: {
    minWidth: 600
  },
  tooltip: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #dadde9',
    fontSize: 14
  }
}

class NotificationsContent extends React.Component {
  constructor(props) {
    super(props)

    const {
      intl: { formatMessage },
      match
    } = props
    this.formatMessage = formatMessage
    const matchParamsNotificationId = match?.params?.notificationId || ''

    this.state = {
      secondaryGlobalQuery: props.secondaryGlobalQuery,
      notificationsTotalsData: props.notificationsTotalsData,

      notifications: [],
      archivedNotificationsHashIds: [],
      selectedNotifications: [],
      selectedNotificationsIds: [],
      selectedNotificationsStatus: [],

      count: reactBootstrapTable.count,

      filter: {},
      filterDates: {},
      filterCriticality: { high: false, medium: false, low: false },
      filterStatus: { open: false, closed: false, acknowledged: false },
      filterArchived: false,

      actionHandler: matchParamsNotificationId === '' ? '' : 'detail',

      tableOptions: {
        // No data
        noDataText: <CircularProgress />,

        // Page size select
        onSizePerPageList: this.onSizePerPageList,
        sizePerPageList: reactBootstrapTable.elementsPerPage, // you can change the dropdown list for size per page
        sizePerPage: reactBootstrapTable.length, // which size per page you want to locate as default
        page: reactBootstrapTable.page, // which page you want to show as default

        // Pagination
        onPageChange: this.onPageChange,
        ignoreSinglePage: false, // Give true will ignore the pagination if only one page, default is false.
        pageStartIndex: 1, // where to start counting the pages
        paginationSize: 5, // the pagination bar size.
        prePage: this.formatMessage(messages.prePage), // Previous page button text
        nextPage: this.formatMessage(messages.nextPage), // Next page button text
        firstPage: this.formatMessage(messages.firstPage), // First page button text
        lastPage: this.formatMessage(messages.lastPage), // Last page button text
        paginationShowsTotal: this.renderPaginationShowsTotal(this.formatMessage), // Accept bool or function
        paginationPosition: 'bottom', // default is bottom, top and both is all available
        hideSizePerPage: false, // You can hide the dropdown for sizePerPage
        alwaysShowAllBtns: false, // Always show next and previous button
        withFirstAndLast: true, // Hide the going to First and Last page button

        // Filter options
        onFilterChange: this.onFilterChange
      },
      visibleColumns: {
        date: true,
        name: true,
        description: false,
        machineName: true,
        deviceType: false,
        type: true,
        severity: true,
        status: true
      }
    }
  }

  // This method is invoked right before calling the render method,
  // both on the initial mount and on subsequent updates. It should return
  // an object to update the state, or null to update nothing.
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.secondaryGlobalQuery !== prevState.secondaryGlobalQuery) {
      return {
        secondaryGlobalQuery: nextProps.secondaryGlobalQuery,
        tableOptions: { ...prevState.tableOptions, page: 1 },
        start: 0
      }
    } else if (nextProps.notificationsTotalsData !== prevState.notificationsTotalsData) {
      return {
        notificationsTotalsData: nextProps.notificationsTotalsData
      }
    }

    return null
  }

  componentDidMount() {
    this.getNotifications()
  }

  componentDidUpdate(prevProps, prevState) {
    const { notificationsTotalsData, secondaryGlobalQuery } = this.state
    if (
      prevState.notificationsTotalsData !== notificationsTotalsData ||
      prevState.secondaryGlobalQuery !== secondaryGlobalQuery
    ) {
      this.getNotifications()
    }
  }

  getNotifications = () => {
    const { currentGroupId } = this.props
    const { filter, filterCriticality, filterStatus, filterArchived, filterDates, secondaryGlobalQuery, tableOptions } =
      this.state

    const query = composeLocalQuery(
      filter,
      filterCriticality,
      filterStatus,
      filterArchived,
      filterDates,
      secondaryGlobalQuery,
      tableOptions.page,
      tableOptions.sizePerPage
    )

    client
      .getNotifications(currentGroupId, query)
      .then(response => {
        const notificationsCount = parseInt(response.headers['x-total-count'], 10)
        const notificationsToShow = getAdaptedNotificationsData(response.data)

        //actualizar la tabla
        const notificationsArchived = []
        if (notificationsToShow.length === 0) {
          this.setState({
            notifications: notificationsToShow,
            count: 0,
            archivedNotificationsHashIds: notificationsArchived,
            tableOptions: { ...tableOptions, noDataText: this.formatMessage(messages.noNotificationsAvailable) }
          })
        } else {
          notificationsToShow.map(theNotification => {
            if (theNotification.archived) {
              notificationsArchived.push(theNotification.hashId)
            }
            return theNotification
          })
          this.setState({
            notifications: notificationsToShow,
            count: notificationsCount,
            archivedNotificationsHashIds: notificationsArchived
          })
        }
      })
      .catch(response => {
        this.setState({
          tableOptions: { ...tableOptions, noDataText: this.formatMessage(messages.noNotificationsAvailable) },
          count: 0
        })
        logError(response)
      })
  }

  onPageChange = (page, sizePerPage) => {
    const { tableOptions } = this.state
    this.setState(
      {
        tableOptions: { ...tableOptions, page, sizePerPage },
        notifications: []
      },
      () => {
        this.getNotifications()
      }
    )
  }

  onSizePerPageList = sizePerPage => {
    const { tableOptions } = this.state
    this.setState(
      {
        tableOptions: { ...tableOptions, sizePerPage },
        notifications: []
      },
      () => {
        this.getNotifications()
      }
    )
  }

  onRowSelect = (row, isSelected) => {
    const { selectedNotifications, selectedNotificationsIds, selectedNotificationsStatus } = this.state

    const element = row
    const elementId = row.hashId
    const elementStatus = row.status

    const indexOfNotification = selectedNotifications.findIndex(i => i.hashId === elementId)

    let newSelectedNotifications = []
    let newSelectedNotificationIds = []
    let newSelectedNotificationsStatus = []
    if (isSelected) {
      if (indexOfNotification < 0) {
        newSelectedNotifications = [...selectedNotifications, element]
        newSelectedNotificationIds = [...selectedNotificationsIds, elementId]
        newSelectedNotificationsStatus = [...selectedNotificationsStatus, elementStatus]
      }
    } else {
      if (indexOfNotification > -1) {
        newSelectedNotifications = selectedNotifications.filter((item, index) => index !== indexOfNotification)
        newSelectedNotificationIds = selectedNotificationsIds.filter((item, index) => index !== indexOfNotification)
        newSelectedNotificationsStatus = selectedNotificationsStatus.filter(
          (item, index) => index !== indexOfNotification
        )
      }
    }

    this.setState({
      selectedNotifications: newSelectedNotifications,
      selectedNotificationsIds: newSelectedNotificationIds,
      selectedNotificationsStatus: newSelectedNotificationsStatus
    })
  }

  onSelectAll = (isSelected, rows) => {
    const { selectedNotifications, selectedNotificationsIds, selectedNotificationsStatus } = this.state

    let indexOfNotification
    let elementId
    let element
    let elementStatus

    const notificationsToProcess = rows.map(notification => {
      return notification
    })

    let newSelectedNotifications = [...selectedNotifications]
    let newSelectedNotificationIds = [...selectedNotificationsIds]
    let newSelectedNotificationsStatus = [...selectedNotificationsStatus]
    notificationsToProcess.map(notification => {
      element = notification
      elementId = notification.hashId
      elementStatus = notification.status
      indexOfNotification = newSelectedNotifications.findIndex(i => i.hashId === elementId)

      if (isSelected) {
        if (indexOfNotification < 0) {
          newSelectedNotifications = [...newSelectedNotifications, element]
          newSelectedNotificationIds = [...newSelectedNotificationIds, elementId]
          newSelectedNotificationsStatus = [...newSelectedNotificationsStatus, elementStatus]
        }
      } else {
        if (indexOfNotification > -1) {
          newSelectedNotifications = newSelectedNotifications.filter((item, index) => index !== indexOfNotification)
          newSelectedNotificationIds = newSelectedNotificationIds.filter((item, index) => index !== indexOfNotification)
          newSelectedNotificationsStatus = newSelectedNotificationsStatus.filter(
            (item, index) => index !== indexOfNotification
          )
        }
      }

      return notification
    })

    this.setState({
      selectedNotifications: newSelectedNotifications,
      selectedNotificationsIds: newSelectedNotificationIds,
      selectedNotificationsStatus: newSelectedNotificationsStatus
    })
  }

  onFilterChange = filterObj => {
    const { tableOptions } = this.state
    this.setState(
      {
        filter: filterObj,
        tableOptions: { ...tableOptions, page: 1 }
      },
      () => {
        this.getNotifications()
      }
    )
    return false
  }

  handleChangeColumnVisibility = columnId => {
    this.setState(state => {
      const updatedColumnVisibility = !state.visibleColumns[columnId]
      return {
        visibleColumns: {
          ...state.visibleColumns,
          [columnId]: updatedColumnVisibility
        }
      }
    })
  }

  formatDate = cell => {
    if (cell !== undefined) {
      return <DateLabel date={cell} format='DD-MMM-YYYY HH:mm:ss' />
    } else {
      return '-'
    }
  }

  formatNotificationType = cell => {
    if (cell === '') return '-'
    else return this.formatMessage(messages[cell])
  }

  formatCriticality = cell => {
    const { intl } = this.props
    return <DataChip chipText={cell} inline intl={intl} />
  }

  formatStatus = cell => {
    const { intl } = this.props
    let currentRowStatus = cell
    if (currentRowStatus === 'acknowledged') {
      currentRowStatus = 'ACKD'
    }
    return <DataChip chipText={currentRowStatus} inline intl={intl} />
  }

  showActions = () => {
    const {
      privileges: { canViewAndEditNotifications }
    } = this.props
    if (canViewAndEditNotifications) {
      return (
        <TableHeaderColumn
          dataAlign='center'
          dataField='hashId'
          dataFormat={this.formatActionDetail}
          headerAlign='center'
          tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
          width='200'
        >
          {this.formatMessage(messages.actions)}
        </TableHeaderColumn>
      )
    } else {
      return <TableHeaderColumn visible='false' width='0' />
    }
  }

  formatActionDetail = (cell, row) => {
    const { classes, currentGroupId } = this.props

    const notificationIsArchived = row.archived
    const status = row.status.toLowerCase()

    const showDetail = true
    let showClose = false
    let showReopen = false
    let showAcknowledge = false
    let showUnacknowledge = false

    if (!notificationIsArchived) {
      switch (status) {
        case 'open':
          showClose = true
          showReopen = false
          showAcknowledge = true
          showUnacknowledge = false
          break
        case 'closed':
          showClose = false
          showReopen = true
          showAcknowledge = false
          showUnacknowledge = false
          break
        case 'acknowledged':
          showClose = true
          showReopen = false
          showAcknowledge = false
          showUnacknowledge = true
          break
        default:
          showClose = false
          showReopen = false
          showAcknowledge = false
          showUnacknowledge = false
      }
    }

    return (
      <div className='users'>
        {showDetail && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.viewActionDetail)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <Link to={{ pathname: getNotificationDetailsUrl(row.hashId), search: `?groupId=${currentGroupId}` }}>
                <IconButton onClick={this.handleAction('detail')} style={{ padding: 5 }}>
                  <SearchIcon />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        )}

        {showClose && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.closeNotification)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <Link to={{ pathname: getNotificationDetailsUrl(row.hashId), search: `?groupId=${currentGroupId}` }}>
                <IconButton onClick={this.handleAction('close')} style={{ padding: 5 }}>
                  <HighlightOffIcon />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        )}

        {showReopen && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.reopenNotification)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <Link to={{ pathname: getNotificationDetailsUrl(row.hashId), search: `?groupId=${currentGroupId}` }}>
                <IconButton onClick={this.handleAction('reopen')} style={{ padding: 5 }}>
                  <ReplayIcon />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        )}

        {showAcknowledge && (
          <Tooltip classes={{ tooltip: classes.tooltip }} title={this.formatMessage(messages.acknowledgeNotification)}>
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <Link to={{ pathname: getNotificationDetailsUrl(row.hashId), search: `?groupId=${currentGroupId}` }}>
                <IconButton onClick={this.handleAction('acknowledge')} style={{ padding: 5 }}>
                  <PersonIcon />
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        )}

        {showUnacknowledge && (
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            title={this.formatMessage(messages.unacknowledgeNotification)}
          >
            <div style={{ padding: 0, display: '-webkit-inline-box' }}>
              <Link to={{ pathname: getNotificationDetailsUrl(row.hashId), search: `?groupId=${currentGroupId}` }}>
                <IconButton onClick={this.handleAction('unacknowledge')} style={{ padding: 5 }}>
                  <svg fill='none' height='20' viewBox='0 0 20 20' width='20' xmlns='http://www.w3.org/2000/svg'>
                    <path
                      d='M7.26 3.01C7.97 1.91 9.21 1.19 10.61 1.19C12.82 1.19 14.61 2.98 14.61 5.19C14.61 6.59 13.89 7.83 12.79 8.54L7.26 3.01ZM18.61 14.36C18.59 13.26 17.98 12.25 17 11.74C16.46 11.46 15.87 11.2 15.23 10.98L18.61 14.36ZM19.8 18.38L1.42 0L0 1.41L8.89 10.3C7.08 10.53 5.5 11.09 4.22 11.75C3.22 12.26 2.61 13.29 2.61 14.41V17.19H15.78L18.39 19.8L19.8 18.38Z'
                      fill='#757575'
                    />
                  </svg>
                </IconButton>
              </Link>
            </div>
          </Tooltip>
        )}
      </div>
    )
  }

  clearSelectedNotifications = () => {
    this.setState({
      selectedNotifications: [],
      selectedNotificationsIds: [],
      selectedNotificationsStatus: []
    })
  }

  setFilterDates = dates => {
    const { tableOptions } = this.state
    this.setState(
      {
        filterDates: dates,
        tableOptions: { ...tableOptions, page: 1 }
      },
      () => {
        this.getNotifications()
      }
    )
  }

  setFilterCriticality = criticality => {
    const { tableOptions } = this.state
    this.setState(
      {
        filterCriticality: criticality,
        tableOptions: { ...tableOptions, page: 1 }
      },
      () => {
        this.getNotifications()
      }
    )
  }

  setFilterStatus = status => {
    const { tableOptions } = this.state
    this.setState(
      {
        filterStatus: status,
        tableOptions: { ...tableOptions, page: 1 }
      },
      () => {
        this.getNotifications()
      }
    )
  }

  setFilterArchived = archived => {
    const { tableOptions } = this.state
    this.setState(
      {
        filterArchived: archived,
        tableOptions: { ...tableOptions, page: 1 }
      },
      () => {
        this.getNotifications()
      }
    )
  }

  closeDialog = isUpdateNeeded => {
    const { history } = this.props
    this.setState(
      {
        actionHandler: ''
      },
      () => {
        history.replace(getNotificationsUrl())
        if (isUpdateNeeded) this.getNotifications()
      }
    )
  }

  handleAction = action => () => this.setState({ actionHandler: action })

  redrawNotificationsTable = newNotification => {
    const { notifications } = this.state
    const updatedNotifications = notifications.map(notification => {
      if (notification.hashId === newNotification.hashId) {
        return { ...notification, status: newNotification.status, version: newNotification.version + 1 }
      } else {
        return notification
      }
    })

    this.setState({
      notifications: updatedNotifications
    })
  }

  renderPaginationShowsTotal = formatMessage => (start, to, total) =>
    (
      <span>
        {`${formatMessage(messages.showingRows)} ${start} ${formatMessage(messages.to)} ${to} ${formatMessage(
          messages.of
        )} ${total}`}
      </span>
    )

  render() {
    const {
      privileges: { canViewAndEditNotifications },
      match
    } = this.props
    const {
      actionHandler,
      archivedNotificationsHashIds,
      count,
      notifications,
      selectedNotifications,
      selectedNotificationsIds,
      selectedNotificationsStatus,
      tableOptions,
      visibleColumns
    } = this.state

    const notificationId = match?.params?.notificationId || ''

    const selectRowProp = {
      mode: 'checkbox',
      clickToSelect: false,
      onSelect: this.onRowSelect,
      onSelectAll: this.onSelectAll,
      bgColor: '#f5f5f5',
      selected: selectedNotificationsIds,
      unselectable: archivedNotificationsHashIds
    }

    const action = actionHandler === 'detail' ? '' : actionHandler.toUpperCase()
    const deviceTypeFilterOptions = {
      CS100: 'CS100',
      CS500: 'CS500',
      CS10: 'CS10'
    }

    return (
      <div>
        <NotificationsFilter
          setFilterArchived={this.setFilterArchived}
          setFilterCriticality={this.setFilterCriticality}
          setFilterDates={this.setFilterDates}
          setFilterStatus={this.setFilterStatus}
        />
        <br />
        <Paper style={{ borderRadius: 0 }}>
          <div className='container-fluid'>
            <div className='row'>
              <div className='col-md-12'>
                <NotificationsGlobalActions
                  canViewAndEditNotifications={canViewAndEditNotifications}
                  clearSelectedNotifications={this.clearSelectedNotifications}
                  onChangeColumnVisibility={this.handleChangeColumnVisibility}
                  redrawNotificationsTable={this.redrawNotificationsTable}
                  selectedNotifications={selectedNotifications}
                  selectedNotificationsStatus={selectedNotificationsStatus}
                  visibleColumns={visibleColumns}
                />
              </div>
            </div>
          </div>
          <div className='table-with-pagination'>
            <BootstrapTable
              bordered={false}
              condensed={false}
              data={notifications}
              exportCSV={false}
              fetchInfo={{ dataTotalSize: count }}
              hover
              multiColumnSearch={false}
              options={tableOptions}
              pagination
              remote={remoteObj => ({
                ...remoteObj,
                search: false,
                pagination: true,
                sizePerPage: true,
                sort: false,
                filter: true
              })}
              search={false}
              searchPlaceholder={this.formatMessage(messages.filterByOrderableColumns)}
              selectRow={selectRowProp}
              striped={false}
            >
              <TableHeaderColumn dataField='hashId' hidden isKey />
              <TableHeaderColumn
                dataField='createdAt'
                dataFormat={this.formatDate}
                hidden={!visibleColumns.date}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='100'
              >
                {this.formatMessage(messages.date)}
              </TableHeaderColumn>
              <TableHeaderColumn
                columnClassName='tbody-column-multiline'
                dataField='ruleInstanceName'
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.name)
                }}
                hidden={!visibleColumns.name}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='100'
              >
                {this.formatMessage(messages.name)}
              </TableHeaderColumn>
              <TableHeaderColumn
                columnClassName='tbody-column-multiline'
                dataField='ruleInstanceDescription'
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.description)
                }}
                hidden={!visibleColumns.description}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='100'
              >
                {this.formatMessage(messages.description)}
              </TableHeaderColumn>
              <TableHeaderColumn
                columnClassName='tbody-column-multiline'
                dataField='deviceName'
                filter={{
                  type: 'TextFilter',
                  delay: 400,
                  placeholder: this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.deviceName)
                }}
                hidden={!visibleColumns.machineName}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='120'
              >
                {this.formatMessage(messages.deviceName)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='deviceType'
                filter={{
                  type: 'SelectFilter',
                  delay: 400,
                  options: deviceTypeFilterOptions,
                  placeholder: this.formatMessage(messages.placeholder) + ' ' + this.formatMessage(messages.deviceType)
                }}
                hidden={!visibleColumns.deviceType}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='100'
              >
                {this.formatMessage(messages.deviceType)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='notificationType'
                dataFormat={this.formatNotificationType}
                hidden={!visibleColumns.type}
                width='120'
              >
                {this.formatMessage(messages.type)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='criticality'
                dataFormat={this.formatCriticality}
                hidden={!visibleColumns.severity}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='120'
              >
                {this.formatMessage(messages.severity)}
              </TableHeaderColumn>
              <TableHeaderColumn
                dataField='status'
                dataFormat={this.formatStatus}
                hidden={!visibleColumns.status}
                tdStyle={{ whiteSpace: 'normal', wordWrap: 'break-word', verticalAlign: 'middle' }}
                width='120'
              >
                {this.formatMessage(messages.status)}
              </TableHeaderColumn>
              {this.showActions()}
            </BootstrapTable>
          </div>
          {notificationId && actionHandler !== '' && (
            <NotificationsDetail action={action} closeDialog={this.closeDialog} notificationId={notificationId} />
          )}
        </Paper>
      </div>
    )
  }
}

NotificationsContent.propTypes = {
  classes: PropTypes.object.isRequired,
  currentGroupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  notificationId: PropTypes.string.isRequired,
  notificationsTotalsData: PropTypes.object,
  privileges: PropTypes.object.isRequired,
  secondaryGlobalQuery: PropTypes.string.isRequired
}

NotificationsContent.defaultProps = {
  notificationsTotalsData: null
}

export default withRouter(withStyles(styles)(injectIntl(NotificationsContent)))
