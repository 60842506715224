import PropTypes from 'prop-types'
import React from 'react'
import { injectIntl } from 'react-intl'
import { Link } from 'react-router-dom'

import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Divider from '@material-ui/core/Divider'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import Autocomplete from '@material-ui/lab/Autocomplete'

import PageTitle from 'components/PageTitle'

import SaveDeviceSucessAlert from '../components/alerts/SaveDeviceSucessAlert'
import ServerErrorAlert from '../components/alerts/ServerErrorAlert'
import { DEVICE_CONFIGURATION_MODES } from '../constants'
import messages from '../messages'
import { deviceConfigurationStyles } from '../styles'
import { getDevicesUrl } from '../urls'

const styles = theme => ({
  ...deviceConfigurationStyles(theme)
})

class DeviceConfiguration extends React.Component {
  constructor(props) {
    super(props)
    const {
      intl: { formatMessage }
    } = props

    this.formatMessage = formatMessage
    this.state = {
      deviceType: '',
      name: '',
      eid: '',
      deviceSerialNumber: '',
      machineModel: '',
      machineType: '',
      machineBrand: '',
      machineSerialNumber: '',
      description: '',
      isDeviceLoading: true,
      isSavingLoading: false,
      isDeviceSaved: false,
      isApiFetchError: false,
      isApiSendError: false
    }
  }

  componentDidMount() {
    const { mode } = this.props
    if (mode === DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION) {
      this.setState({ isDeviceLoading: false })
    }

    if (mode === DEVICE_CONFIGURATION_MODES.EDIT_DEVICE_CONFIGURATION) {
      this.fetchDeviceData()
    }
  }

  fetchDeviceData = () => {
    const {
      getDevice,
      getDevicesList,
      groupId,
      match: {
        params: { deviceId }
      }
    } = this.props

    this.setState({ isDeviceLoading: true, isApiFetchError: false })
    getDevice(deviceId, groupId)
      .then(response => {
        const { EID } = response.data
        return getDevicesList(groupId, [EID])
      })
      .then(response => {
        const [
          {
            device_type: deviceType,
            name,
            EID: eid,
            serialNumber: deviceSerialNumber,
            machineModel,
            machineType,
            machineBrand,
            machineSerialNumber,
            description
          }
        ] = response.data.devices
        this.setState({
          deviceType,
          name,
          eid,
          deviceSerialNumber,
          machineModel: machineModel || '',
          machineType: machineType || '',
          machineBrand: machineBrand || '',
          machineSerialNumber: machineSerialNumber || '',
          description: description || ''
        })
      })
      .catch(() => {
        this.setState({ isApiFetchError: true })
      })
      .finally(() => {
        this.setState({ isDeviceLoading: false })
      })
  }

  getPreviousLocation = () => {
    const {
      mode,
      location: { state }
    } = this.props
    let previousLocation = ''
    if (mode === DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION || !state?.from) {
      previousLocation = getDevicesUrl()
    } else previousLocation = state.from
    return previousLocation
  }

  handleFieldChange = event => {
    const { deviceType } = this.state
    const value = event.target.value
    const field = event.target.id

    let newValue = value
    if (field === 'deviceSerialNumber') {
      const reg = deviceType === 'CS10' ? /[a-z0-9\-]+/ : /[0-9]+/
      newValue = (value.match(reg) || []).join('')
    }
    this.setState({ [field]: newValue })
  }

  handleDeviceTypeChange = (event, value) => {
    const { id: deviceType } = value || { id: '' }
    this.setState({ deviceType })
  }

  handleAutocompleteClick = () => {
    const { mode } = this.props
    const storedData = localStorage.getItem('lastRegisteredDevice')
    const isRegisterMode = mode === DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION
    let parsedData = null
    try {
      parsedData = JSON.parse(storedData)
    } catch (error) {
      parsedData = null
    }
    this.setState({
      ...isRegisterMode && {
        eid: parsedData?.eid || '',
        deviceSerialNumber: parsedData?.deviceSerialNumber || '',
        deviceType: parsedData?.deviceType || ''
      },
      name: parsedData?.name || '',
      machineModel: parsedData?.machineModel || '',
      machineType: parsedData?.machineType || '',
      machineBrand: parsedData?.machineBrand || '',
      machineSerialNumber: parsedData?.machineSerialNumber || '',
      description: parsedData?.description || ''
    })
  }

  handleCloseClick = () => {
    this.setState({ isApiSendError: false })
  }

  handleSaveClick = () => {
    const {
      groupId,
      match: {
        params: { deviceId }
      },
      mode,
      activateDevice,
      updateDevice
    } = this.props
    const {
      name,
      machineModel,
      machineType,
      machineBrand,
      machineSerialNumber,
      description,
      eid: EID,
      deviceSerialNumber: serialNumber,
      deviceType
    } = this.state

    this.setState({ isSavingLoading: true, isApiSendError: false, isDeviceSaved: false })

    const body = {
      name,
      machineModel,
      machineType,
      machineBrand,
      machineSerialNumber,
      description
    }

    let deviceSavePromise = null
    if (mode === DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION) {
      body.EID = EID.toLowerCase()
      delete body.description
      if (deviceType === 'CS10') body.regCode = serialNumber
      else body.serialNumber = serialNumber
      deviceSavePromise = activateDevice(body)
    } else {
      deviceSavePromise = updateDevice(deviceId, groupId, body)
    }

    deviceSavePromise
      .then(() => {
        this.setState({ isDeviceSaved: true })
        this.storeDevice()
      })
      .catch(() => {
        this.setState({ isApiSendError: true })
      })
      .finally(() => {
        this.setState({ isSavingLoading: false })
      })
  }

  handleExitClick = () => {
    const { history } = this.props
    history.push(this.getPreviousLocation())
  }

  storeDevice = () => {
    const {
      deviceType,
      name,
      eid,
      deviceSerialNumber,
      machineModel,
      machineType,
      machineBrand,
      machineSerialNumber,
      description
    } = this.state
    const registeredDeviceMachineFields = {
      deviceType,
      name,
      eid,
      deviceSerialNumber,
      machineModel,
      machineType,
      machineBrand,
      machineSerialNumber,
      description
    }
    localStorage.setItem('lastRegisteredDevice', JSON.stringify(registeredDeviceMachineFields))
  }

  render() {
    const { classes, mode } = this.props
    const {
      deviceType,
      name,
      eid,
      deviceSerialNumber,
      machineModel,
      machineType,
      machineBrand,
      machineSerialNumber,
      description,
      isSavingLoading,
      isApiSendError,
      isDeviceSaved,
      isApiFetchError,
      isDeviceLoading
    } = this.state

    const deviceTypeOptions = [
      { name: 'CS10', id: 'CS10' },
      { name: 'CS100', id: 'CS100' },
      { name: 'CS500', id: 'CS500' }
    ]

    const isSaveButtonDisabled =
      deviceType === '' || name === '' || eid === '' || deviceSerialNumber === '' || isSavingLoading || isApiFetchError

    const isEditMode = mode === DEVICE_CONFIGURATION_MODES.EDIT_DEVICE_CONFIGURATION
    const isFormFieldDisabled = isApiFetchError || isSavingLoading || isDeviceSaved

    const isCancelButtonShown = !isDeviceLoading && !isDeviceSaved
    const isSaveButtonShown = !isDeviceLoading && !isDeviceSaved
    const isExitButtonShown = !isDeviceLoading && isDeviceSaved

    let pageTitle = ''

    if (mode === DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION) {
      pageTitle = this.formatMessage(messages.registerDevice)
    } else if (mode === DEVICE_CONFIGURATION_MODES.EDIT_DEVICE_CONFIGURATION) {
      pageTitle = this.formatMessage(messages.editDeviceAndMachineInformation)
    }

    return (
      <div style={{ margin: '20px 25px 20px 20px' }}>
        <div className='container-fluid'>
          <PageTitle back={this.getPreviousLocation()} title={pageTitle} />
        </div>
        <div className='row' style={{ margin: '20px 0 0 0' }}>
          <div className='container-fluid'>
            <Grid container spacing={3}>
              <Grid container item justifyContent='center' xs={12}>
                {isDeviceLoading ? (
                  <CircularProgress />
                ) : (
                  <Paper classes={{ root: classes.paperRoot }}>
                    <Grid alignItems='baseline' container spacing={3}>
                      {isApiFetchError && (
                        <Grid item xs={12}>
                          <ServerErrorAlert />
                        </Grid>
                      )}
                      <Grid container item lg={6} spacing={3} xs={12}>
                        <Grid item xs={12}>
                          <Typography component='h2' variant='h5'>
                            {this.formatMessage(messages.ioTDeviceInformation)}
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Autocomplete
                            disabled={isEditMode}
                            fullWidth
                            getOptionLabel={option => option.name}
                            getOptionSelected={(option, value) => option.id === value.id}
                            onChange={this.handleDeviceTypeChange}
                            options={deviceTypeOptions}
                            renderInput={params => (
                              <TextField
                                {...params}
                                id='deviceType'
                                label={this.formatMessage(messages.deviceType)}
                                required
                              />
                            )}
                            value={
                              deviceTypeOptions.find(deviceTypeOption => deviceTypeOption.id === deviceType) || null
                            }
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isFormFieldDisabled}
                            fullWidth
                            helperText={this.formatMessage(messages.nameHelperMessage)}
                            id='name'
                            label={this.formatMessage(messages.name)}
                            onChange={this.handleFieldChange}
                            required
                            value={name}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isEditMode}
                            fullWidth
                            id='eid'
                            label={this.formatMessage(messages.eid)}
                            onChange={this.handleFieldChange}
                            required
                            value={eid}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isEditMode}
                            fullWidth
                            id='deviceSerialNumber'
                            label={
                              !isEditMode && deviceType == 'CS10'
                                ? this.formatMessage(messages.registerCode)
                                : this.formatMessage(messages.deviceSerialNumber)
                            }
                            onChange={this.handleFieldChange}
                            required
                            type='text'
                            value={deviceSerialNumber}
                          />
                        </Grid>
                        <Grid alignItems='center' container item xs={12}>
                          <Grid item>{this.formatMessage(messages.requiredFieldsMessage)}</Grid>
                        </Grid>
                      </Grid>
                      <Grid container item lg={6} spacing={3} xs={12}>
                        <Grid item sm={6} xs={12}>
                          <Typography component='h2' variant='h5'>
                            {this.formatMessage(messages.machineInformation)}
                          </Typography>
                        </Grid>
                        <Grid container item sm={6} style={{ height: 56 }} xs={12}>
                          <Grid item xs={1}>
                            <Divider orientation='vertical' />
                          </Grid>
                          <Grid item style={{ fontSize: 12 }} xs={7}>
                            {this.formatMessage(messages.autocompleteHelperText)}
                          </Grid>
                          <Grid alignItems='center' container item justifyContent='flex-end' xs={4}>
                            <Button
                              className='secondary-action-button'
                              classes={{ label: classes.buttonLabel }}
                              disabled={isFormFieldDisabled}
                              onClick={this.handleAutocompleteClick}
                            >
                              {this.formatMessage(messages.autocomplete)}
                            </Button>
                          </Grid>
                        </Grid>

                        <Grid item xs={6}>
                          <TextField
                            disabled
                            fullWidth
                            helperText={this.formatMessage(messages.machineNameHelperMessage)}
                            id='nameMachine'
                            label={this.formatMessage(messages.name)}
                            value={name}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isFormFieldDisabled}
                            fullWidth
                            id='machineModel'
                            label={this.formatMessage(messages.machineModel)}
                            onChange={this.handleFieldChange}
                            value={machineModel}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isFormFieldDisabled}
                            fullWidth
                            id='machineType'
                            label={this.formatMessage(messages.machineType)}
                            onChange={this.handleFieldChange}
                            value={machineType}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isFormFieldDisabled}
                            fullWidth
                            id='machineBrand'
                            label={this.formatMessage(messages.machineBrand)}
                            onChange={this.handleFieldChange}
                            value={machineBrand}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                            disabled={isFormFieldDisabled}
                            fullWidth
                            id='machineSerialNumber'
                            label={this.formatMessage(messages.machineSerialNumber)}
                            onChange={this.handleFieldChange}
                            value={machineSerialNumber}
                          />
                        </Grid>
                        {isEditMode && (
                          <Grid item xs={6}>
                            <TextField
                              InputLabelProps={{ shrink: true }}
                              disabled={isFormFieldDisabled}
                              fullWidth
                              id='description'
                              label={this.formatMessage(messages.description)}
                              margin='dense'
                              minRows='3'
                              multiline
                              onChange={this.handleFieldChange}
                              value={description}
                              variant='outlined'
                            />
                          </Grid>
                        )}
                      </Grid>
                      {isDeviceSaved && (
                        <Grid item xs={12}>
                          <SaveDeviceSucessAlert />
                        </Grid>
                      )}
                      {isApiSendError && (
                        <Grid item xs={12}>
                          <ServerErrorAlert />
                        </Grid>
                      )}
                    </Grid>
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent='flex-end' spacing={1}>
                  {isCancelButtonShown && (
                    <Grid item>
                      <Link className='button-link' to={this.getPreviousLocation()}>
                        <Button className='cancel-button'>{this.formatMessage(messages.cancel)}</Button>
                      </Link>
                    </Grid>
                  )}
                  {isSaveButtonShown && (
                    <Grid item>
                      <Button
                        className='primary-action-button'
                        disabled={isSaveButtonDisabled}
                        onClick={this.handleSaveClick}
                      >
                        {isSavingLoading ? <CircularProgress size={20} /> : this.formatMessage(messages.save)}
                      </Button>
                    </Grid>
                  )}
                  {isExitButtonShown && (
                    <Grid item>
                      <Button className='primary-action-button' onClick={this.handleExitClick}>
                        {this.formatMessage(messages.exit)}
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </div>
        </div>
      </div>
    )
  }
}

DeviceConfiguration.propTypes = {
  activateDevice: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  getDevice: PropTypes.func.isRequired,
  getDevicesList: PropTypes.func.isRequired,
  groupId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  intl: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  mode: PropTypes.oneOf([
    DEVICE_CONFIGURATION_MODES.REGISTER_DEVICE_CONFIGURATION,
    DEVICE_CONFIGURATION_MODES.EDIT_DEVICE_CONFIGURATION
  ]).isRequired,
  updateDevice: PropTypes.func.isRequired
}

export default withStyles(styles)(injectIntl(DeviceConfiguration))
