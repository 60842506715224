import { create, all } from 'mathjs'

const unitTypeValidValues = {
  PREDEFINED_UNIT_TYPE: 0,
  CUSTOM_UNIT_TYPE: 1
}

export const SIGNEDNESS_VALID_VALUES = {
  UNSIGNED: 0,
  SIGNED: 1
}

export const ENDIANNESS_VALID_VALUES = {
  LITTLE_ENDIAN: 0,
  BIG_ENDIAN: 1
}

const hexToDecCanId = (hexCanId, isExtendedCanId) => {
  let decCanId

  if (isExtendedCanId) {
    let binCanId = parseInt(hexCanId, 16).toString(2)
    for (let i = binCanId.length; i < 31; i++) {
      binCanId = '0' + binCanId
    }
    binCanId = '1' + binCanId
    decCanId = parseInt(binCanId, 2)
  } else {
    decCanId = parseInt(hexCanId, 16)
  }

  return decCanId
}

function getFormatWithSignednessAndEndianness(format = 0, signedness, endianness) {
  const formatBinary = format.toString(2).padStart(6, '0') + endianness.toString(2) + signedness.toString(2)
  return parseInt(formatBinary, 2)
}

export const initCANMessage = () => ({
  byte0: '',
  byte0ErrorMessage: '',
  byte1: '',
  byte1ErrorMessage: '',
  byte2: '',
  byte2ErrorMessage: '',
  byte3: '',
  byte3ErrorMessage: '',
  byte4: '',
  byte4ErrorMessage: '',
  byte5: '',
  byte5ErrorMessage: '',
  byte6: '',
  byte6ErrorMessage: '',
  byte7: '',
  byte7ErrorMessage: '',
  canId: '',
  canIdErrorMessage: '',
  canMessageId: 0,
  canPort: '',
  canPortErrorMessage: '',
  dataSource: '',
  dataSourceErrorMessage: '',
  dataLength: 8,
  dataLengthErrorMessage: '',
  expanded: false,
  isExtendedCanId: false,
  lowerThreshold: '',
  lowerThresholdErrorMessage: '',
  periodType: '',
  periodTypeErrorMessage: '',
  periodTypeTriggered: '',
  periodTypeTriggeredErrorMessage: '',
  sendPeriod: '',
  sendPeriodErrorMessage: '',
  sendPeriodTriggered: '',
  sendPeriodTriggeredErrorMessage: '',
  signalDest: 2,
  thresholdDivider: '',
  thresholdDividerErrorMessage: '',
  triggerSource: '',
  triggerSourceErrorMessage: '',
  triggerType: '',
  triggerTypeErrorMessage: '',
  upperThreshold: '',
  upperThresholdErrorMessage: '',
  virtualSignalNumber: '',
  virtualSignalNumberErrorMessage: ''
})

export const initSignal = () => ({
  signalId: 0,
  signalIdErrorMessage: '',
  cch: '',
  cchErrorMessage: '',
  divider: '',
  dividerErrorMessage: '',
  editableId: false,
  expanded: false,
  isExtendedCanId: false,
  lengthOfBits: '',
  lengthOfBitsErrorMessage: '',
  multiplier: '',
  multiplierErrorMessage: '',
  name: '',
  nameErrorMessage: '',
  offset: '',
  offsetErrorMessage: '',
  piu: '',
  piuErrorMessage: '',
  signalSource: 2,
  signalSourceErrorMessage: '',
  sml: '',
  smlErrorMessage: '',
  ss: '',
  ssErrorMessage: '',
  unit: '',
  unitErrorMessage: '',
  unitType: 0,
  // Filter
  cornerFrequency: '',
  cornerFrequencyErrorMessage: '',
  filterType: 0,
  filterTypeErrorMessage: '',
  gain: '',
  gainErrorMessage: '',
  quotient: '',
  quotientErrorMessage: '',
  // Logging
  logToPortal: true,
  logType: 2,
  logTypeErrorMessage: '',
  samplePeriod: '',
  samplePeriodErrorMessage: '',
  sendImmediately: false,
  // Trigger
  samplePeriodTriggered: '',
  samplePeriodTriggeredErrorMessage: '',
  triggerLogType: 2,
  triggerLogTypeErrorMessage: '',
  triggerLowerThreshold: '',
  triggerLowerThresholdErrorMessage: '',
  triggerSendImmediately: false,
  triggerSendImmediatelyErrorMessage: '',
  triggerSource: '',
  triggerSourceErrorMessage: '',
  triggerThresholdDivider: '',
  triggerThresholdDividerErrorMessage: '',
  triggerType: 0,
  triggerTypeErrorMessage: '',
  triggerUpperThreshold: '',
  triggerUpperThresholdErrorMessage: '',
  endianness: ENDIANNESS_VALID_VALUES.LITTLE_ENDIAN,
  endiannessErrorMessage: '',
  signedness: SIGNEDNESS_VALID_VALUES.UNSIGNED,
  signednessErrorMessage: ''
})

export const initialValues = () => ({
  nodeId: 36,
  nodeIdErrorMessage: '',
  loopTime: 10,
  loopTimeErrorMessage: '',
  nodeId0: 120,
  nodeId0ErrorMessage: '',
  nodeId1: 120,
  nodeId1ErrorMessage: '',
  baudRate0: 3,
  baudRate0ErrorMessage: '',
  baudRate1: 3,
  baudRate1ErrorMessage: '',
  sa0: 255,
  sa0ErrorMessage: '',
  sa1: 255,
  sa1ErrorMessage: '',
  loggingUploadPeriod0: 300,
  loggingUploadPeriod0ErrorMessage: '',
  loggingUploadPeriod1: 300,
  loggingUploadPeriod1ErrorMessage: '',
  interlinkEnabled: true,
  fotaUpdateEnabled: true,
  restartCellularModule: true,
  // GNSS
  gnssPositionEnabled: false,
  positionLogTime: 300,
  positionLogTimeErrorMessage: '',
  gnssAltitudeEnabled: false,
  altitudeLogTime: 300,
  altitudeLogTimeErrorMessage: '',
  gnssSpeedEnabled: false,
  speedLogTime: 300,
  speedLogTimeErrorMessage: '',
  gnssHeadingEnabled: false,
  headingLogTime: 300,
  headingLogTimeErrorMessage: '',
  // GEOFENCE
  geofenceEnabled: false,
  geofenceLogToPortal: false,
  geofenceSendImmediately: false,
  geofenceSamplePeriod: 300,
  geofenceSamplePeriodErrorMessage: '',
  geofenceLatitude: '',
  geofenceLatitudeErrorMessage: '',
  geofenceLongitude: '',
  geofenceLongitudeErrorMessage: '',
  geofenceRadius: '',
  geofenceRadiusErrorMessage: '',
  // DM1
  dm1LogToPortal: false,
  dm1Port: 1,
  dm1PortErrorMessage: '',
  dm1UseSA: false,
  dm1SA: '',
  dm1SAErrorMessage: '',
  dm1Expanded: false,
  // MACHINE STATE DETERMINATION
  forceFullAccessMode: false,
  enableCan: false,
  canPort: 0,
  canPortErrorMessage: '',
  canId: '',
  canIdErrorMessage: '',
  byte0: '',
  byte0ErrorMessage: '',
  byte1: '',
  byte1ErrorMessage: '',
  byte2: '',
  byte2ErrorMessage: '',
  byte3: '',
  byte3ErrorMessage: '',
  byte4: '',
  byte4ErrorMessage: '',
  byte5: '',
  byte5ErrorMessage: '',
  byte6: '',
  byte6ErrorMessage: '',
  byte7: '',
  byte7ErrorMessage: '',
  enableDigitalInput: false,
  digitalInputPin: 0,
  digitalInputPinErrorMessage: '',
  enableServiceToolButton: false,
  machineStateDeterminationExpanded: false,
  // MFIO settings
  bias0: 0,
  bias0ErrorMessage: '',
  digThreshHigh0: 3000,
  digThreshHigh0ErrorMessage: '',
  digThreshLow0: 2000,
  digThreshLow0ErrorMessage: '',
  inputRange0: 0,
  inputRange0ErrorMessage: '',
  inputSignal0: 0,
  inputSignal0ErrorMessage: '',
  bias1: 0,
  bias1ErrorMessage: '',
  digThreshHigh1: 3000,
  digThreshHigh1ErrorMessage: '',
  digThreshLow1: 2000,
  digThreshLow1ErrorMessage: '',
  inputRange1: 0,
  inputRange1ErrorMessage: '',
  inputSignal1: 0,
  inputSignal1ErrorMessage: '',
  bias2: 0,
  bias2ErrorMessage: '',
  digThreshHigh2: 3000,
  digThreshHigh2ErrorMessage: '',
  digThreshLow2: 2000,
  digThreshLow2ErrorMessage: '',
  inputRange2: 0,
  inputRange2ErrorMessage: '',
  inputSignal2: 0,
  inputSignal2ErrorMessage: '',
  bias3: 0,
  bias3ErrorMessage: '',
  digThreshHigh3: 3000,
  digThreshHigh3ErrorMessage: '',
  digThreshLow3: 2000,
  digThreshLow3ErrorMessage: '',
  inputRange3: 0,
  inputRange3ErrorMessage: '',
  inputSignal3: 0,
  inputSignal3ErrorMessage: '',

  signals: [],
  sendCANMessages: [],
  alertVisibility: 'hidden',
  alertMessageTitle: '',
  alertMessageText: ''
})

const transformSignalValuesForAPI = (signalValues, isLegacyVersion = true) => {
  const signalIdToSignalConfigBase = signalId => {
    const n = Math.floor(signalId / 10)
    const m = signalId % 10
    return `SignalConfig${n}__${m}_`
  }

  let signals = []

  signalValues.forEach(s => {
    const signalConfigBase = signalIdToSignalConfigBase(s.signalId)
    let signalConfig = [
      {
        id: signalConfigBase + 'SignalSource_NV',
        value: s.signalSource
      },
      {
        id: signalConfigBase + 'LogToPortal_NV',
        value: s.logToPortal ? 1 : 0
      },
      {
        id: signalConfigBase + 'CCH_NV',
        value: s.cch
      }
    ]
    switch (s.signalSource) {
      case 1: // CAN_J1939
        signalConfig = signalConfig.concat([
          {
            id: signalConfigBase + 'PIU_NV',
            value: parseInt(s.piu)
          },
          {
            id: signalConfigBase + 'SML_NV',
            value: parseInt(s.sml)
          },
          {
            id: signalConfigBase + 'SS__NV',
            value: s.ss
          }
        ])
        break
      case 2: // CAN_Customized
        signalConfig = signalConfig.concat([
          {
            id: signalConfigBase + 'PIU_NV',
            value: hexToDecCanId(s.piu, s.isExtendedCanId)
          },
          {
            id: signalConfigBase + 'SML_NV',
            value: parseInt(s.sml, 16)
          },
          {
            id: signalConfigBase + 'SS__NV',
            value: s.ss
          },
          {
            id: signalConfigBase + '_L__NV',
            value: s.lengthOfBits
          },
          {
            id: signalConfigBase + '_MM_NV',
            value: s.multiplier
          },
          {
            id: signalConfigBase + '_DD_NV',
            value: s.divider
          },
          {
            id: signalConfigBase + '_OO_NV',
            value: s.offset
          },
          {
            id: signalConfigBase + '_UU_NV',
            value: s.unitType === unitTypeValidValues.CUSTOM_UNIT_TYPE ? 0 : s.unit
          }
        ])

        if (
          Object.values(ENDIANNESS_VALID_VALUES).includes(s.endianness) &&
          Object.values(SIGNEDNESS_VALID_VALUES).includes(s.signedness) &&
          !isLegacyVersion
        ) {
          signalConfig.push({
            id: signalConfigBase + 'Format_NV',
            value: getFormatWithSignednessAndEndianness(s.format, s.endianness, s.signedness)
          })
        }

        break
      case 3:
        signalConfig = signalConfig.concat([
          {
            id: signalConfigBase + '_MM_NV',
            value: s.multiplier
          },
          {
            id: signalConfigBase + '_DD_NV',
            value: s.divider
          },
          {
            id: signalConfigBase + '_OO_NV',
            value: s.offset
          },
          {
            id: signalConfigBase + 'PIU_NV',
            value: parseInt(s.piu)
          },
          {
            id: signalConfigBase + 'SML_NV',
            value: parseInt(s.sml)
          },
          {
            id: signalConfigBase + '_UU_NV',
            value: s.unitType === unitTypeValidValues.CUSTOM_UNIT_TYPE ? 0 : s.unit
          }
        ])
        break
      default:
    }

    // Filter
    let filterConfig = []

    if (s.signalId < 40) {
      filterConfig = filterConfig.concat([
        {
          id: signalConfigBase + 'Filtertype_NV',
          value: s.filterType
        }
      ])
    }

    if (s.signalId < 40 && s.filterType > 0 && s.filterType < 6) {
      filterConfig = filterConfig.concat([
        {
          id: signalConfigBase + 'Fc_AmountSamples_NV',
          value: s.cornerFrequency
        },
        {
          id: signalConfigBase + 'Q___NV',
          value: s.quotient
        }
      ])
    }
    if (s.signalId < 40 && s.filterType === 5) {
      filterConfig = filterConfig.concat([
        {
          id: signalConfigBase + 'Gain___NV',
          value: s.gain
        }
      ])
    }
    // Trigger
    let triggerConfig = []
    if (s.signalId < 40 && s.logToPortal && s.filterType !== 6) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'SendImmediatly_NV',
          value: s.sendImmediately ? 1 : 0
        }
      ])
    }
    if (
      s.logToPortal &&
        s.signalId < 40 &&
        !s.sendImmediately &&
        s.triggerType !== 3 &&
        s.triggerType !== 4 &&
        s.triggerType !== 8 &&
        s.filterType !== 6 ||
      s.logToPortal && s.signalId >= 40
    ) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'Sampleperiod_NV',
          value: s.logType === 1 ? 4294967295 : s.samplePeriod
        }
      ])
    }
    if (s.signalId < 40 && s.logToPortal && !s.sendImmediately && s.filterType !== 6) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'Triggertype_NV',
          value: s.triggerType
        }
      ])
    }
    if (s.signalId < 40 && s.logToPortal && !s.sendImmediately && s.filterType !== 6 && s.triggerType !== 0) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'TriggerSource_NV',
          value: parseInt(s.triggerSource)
        }
      ])
    }
    if (
      s.logToPortal &&
      s.signalId < 40 &&
      !s.sendImmediately &&
      s.filterType !== 6 &&
      s.triggerType !== 0 &&
      s.triggerType !== 3 &&
      s.triggerType !== 4 &&
      s.triggerType !== 8
    ) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'SampleperiodTriggered_NV',
          value: s.triggerLogType === 1 ? 4294967295 : s.samplePeriodTriggered
        }
      ])
    }
    if (
      s.logToPortal &&
      s.signalId < 40 &&
      !s.sendImmediately &&
      s.filterType !== 6 &&
      (s.triggerType === 3 || s.triggerType === 4 || s.triggerType === 8)
    ) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'SendImmediatlyTrigger_NV',
          value: s.triggerSendImmediately ? 1 : 1
        }
      ])
    }
    if (
      s.logToPortal &&
      s.signalId < 40 &&
      !s.sendImmediately &&
      s.filterType !== 6 &&
      s.triggerType !== 0 &&
      s.triggerType !== 8
    ) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'UThreshold_NV',
          value: s.triggerUpperThreshold
        }
      ])
    }
    if (
      s.logToPortal &&
      s.signalId < 40 &&
      !s.sendImmediately &&
      s.filterType !== 6 &&
      (s.triggerType === 5 || s.triggerType === 6)
    ) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'LThreshold_NV',
          value: s.triggerLowerThreshold
        }
      ])
    }
    if (
      s.logToPortal &&
      s.signalId < 40 &&
      !s.sendImmediately &&
      s.filterType !== 6 &&
      s.triggerType !== 0 &&
      s.triggerType !== 8
    ) {
      triggerConfig = triggerConfig.concat([
        {
          id: signalConfigBase + 'Divider_NV',
          value: s.triggerThresholdDivider
        }
      ])
    }

    signals = signals.concat(signalConfig, filterConfig, triggerConfig)
  })
  return signals
}

const transformGeofenceValuesForAPI = values => {
  let geofenceParameters = [
    {
      id: 'Geofence_Config_Enable_NV',
      value: values.geofenceEnabled ? 1 : 0
    },
    {
      id: 'Geofence_Config_LogToPortal_NV',
      value: values.geofenceLogToPortal
    },
    {
      id: 'Geofence_Config_SendImmediatly_NV',
      value: values.geofenceSendImmediately
    }
  ]

  if (values.geofenceEnabled) {
    geofenceParameters = geofenceParameters.concat([
      {
        id: 'Geofence_Config_Latitude_NV',
        value: values.geofenceLatitude
      },
      {
        id: 'Geofence_Config_Longitude_NV',
        value: values.geofenceLongitude
      },
      {
        id: 'Geofence_Config_Radius_NV',
        value: values.geofenceRadius
      },
      {
        id: 'Geofence_Config_Sampleperiod_NV',
        value: values.geofenceLogToPortal ? values.geofenceSamplePeriod : 10
      }
    ])
  }

  return geofenceParameters
}

const transformSendCANMessagesValuesForAPI = (sendCANMessagesValues, isLegacyVersion = true) => {
  const messageIdToSendCANMessageBase = canMessageId => {
    return `CANSend${canMessageId}_`
  }

  let sendCANMessages = []

  sendCANMessagesValues.forEach(m => {
    const sendCANMessageBase = messageIdToSendCANMessageBase(m.canMessageId)
    const sendCANMessageBasicConfig = [
      {
        id: sendCANMessageBase + 'SignalDest_NV',
        value: 2 //m.signalDest
      },
      {
        id: sendCANMessageBase + 'CANPort_NV',
        value: m.canPort
      },
      {
        id: sendCANMessageBase + 'ID_NV',
        value: hexToDecCanId(m.canId, m.isExtendedCanId)
      },
      {
        id: sendCANMessageBase + 'DataSource_NV',
        value: m.dataSource !== 255 && m.dataSource !== 240 ? parseInt(m.virtualSignalNumber) : parseInt(m.dataSource)
      },
      {
        id: sendCANMessageBase + 'Byte0_NV',
        value: parseInt(m.byte0, 16)
      },
      {
        id: sendCANMessageBase + 'Byte1_NV',
        value: parseInt(m.byte1, 16)
      },
      {
        id: sendCANMessageBase + 'Byte2_NV',
        value: parseInt(m.byte2, 16)
      },
      {
        id: sendCANMessageBase + 'Byte3_NV',
        value: parseInt(m.byte3, 16)
      },
      {
        id: sendCANMessageBase + 'Byte4_NV',
        value: parseInt(m.byte4, 16)
      },
      {
        id: sendCANMessageBase + 'Byte5_NV',
        value: parseInt(m.byte5, 16)
      },
      {
        id: sendCANMessageBase + 'Byte6_NV',
        value: parseInt(m.byte6, 16)
      },
      {
        id: sendCANMessageBase + 'Byte7_NV',
        value: parseInt(m.byte7, 16)
      }
    ]

    if (!isLegacyVersion && m.dataLength) {
      sendCANMessageBasicConfig.push({
        id: sendCANMessageBase + 'Datalength_NV',
        value: parseInt(m.dataLength)
      })
    }

    let sendCANMessageTransmitTimingsConfig = []

    if (m.triggerType !== 3 && m.triggerType !== 4 && m.triggerType !== 8) {
      sendCANMessageTransmitTimingsConfig = sendCANMessageTransmitTimingsConfig.concat([
        {
          id: sendCANMessageBase + 'Sampleperiod_NV',
          value: m.periodType === 0 ? 4294967295 : parseInt(m.sendPeriod)
        }
      ])
    }

    let sendCANMessageTriggerConfig = [
      {
        id: sendCANMessageBase + 'Triggertype_NV',
        value: m.triggerType
      }
    ]

    if (m.triggerType !== 0) {
      sendCANMessageTriggerConfig = sendCANMessageTriggerConfig.concat([
        {
          id: sendCANMessageBase + 'TriggerSource_NV',
          value: parseInt(m.triggerSource)
        }
      ])
    }

    if (m.triggerType !== 0 && m.triggerType !== 3 && m.triggerType !== 4 && m.triggerType !== 8) {
      sendCANMessageTriggerConfig = sendCANMessageTriggerConfig.concat([
        {
          id: sendCANMessageBase + 'SampleperiodTriggered_NV',
          value: m.periodTypeTriggered === 0 ? 4294967295 : parseInt(m.sendPeriodTriggered)
        }
      ])
    }

    if (m.triggerType !== 0 && m.triggerType !== 8) {
      sendCANMessageTriggerConfig = sendCANMessageTriggerConfig.concat([
        {
          id: sendCANMessageBase + 'UThreshold_NV',
          value: m.upperThreshold
        }
      ])
    }

    if (m.triggerType === 5 || m.triggerType === 6) {
      sendCANMessageTriggerConfig = sendCANMessageTriggerConfig.concat([
        {
          id: sendCANMessageBase + 'LThreshold_NV',
          value: m.lowerThreshold
        }
      ])
    }

    if (m.triggerType !== 0 && m.triggerType !== 8) {
      sendCANMessageTriggerConfig = sendCANMessageTriggerConfig.concat([
        {
          id: sendCANMessageBase + 'Divider_NV',
          value: m.thresholdDivider
        }
      ])
    }

    sendCANMessages = sendCANMessages.concat(
      sendCANMessageBasicConfig,
      sendCANMessageTransmitTimingsConfig,
      sendCANMessageTriggerConfig
    )
  })
  return sendCANMessages
}

const transformDM1ValuesForAPI = values => {
  let dm1Parameters = [
    {
      id: 'DM1_LogToPortal_NV',
      value: values.dm1LogToPortal
    },
    {
      id: 'DM1_Port_NV',
      value: values.dm1Port
    },
    {
      id: 'DM1_Use_SA_NV',
      value: values.dm1UseSA
    }
  ]

  if (values.dm1UseSA) {
    dm1Parameters = dm1Parameters.concat([
      {
        id: 'DM1_SA_NV',
        value: parseInt(values.dm1SA, 16)
      }
    ])
  }

  return dm1Parameters
}

const transformMachineStateDeterminationValuesForAPI = values => {
  let machineStateDeterminationParameters = [
    {
      id: 'SafeMode_ForcedNormal_NV',
      value: values.forceFullAccessMode
    },
    {
      id: 'SafeMode_CAN_Enable_NV',
      value: values.enableCan
    },
    {
      id: 'SafeMode_Pin_Enable_NV',
      value: values.enableDigitalInput
    },
    {
      id: 'SafeMode_Button_Enable_NV',
      value: values.enableServiceToolButton
    }
  ]

  if (values.enableCan) {
    machineStateDeterminationParameters = machineStateDeterminationParameters.concat([
      {
        id: 'SafeMode_CAN_Port_NV',
        value: values.canPort
      },
      {
        id: 'SafeMode_CAN_CANMSG_ID_NV',
        value: parseInt(values.canId, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte0_NV',
        value: parseInt(values.byte0, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte1_NV',
        value: parseInt(values.byte1, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte2_NV',
        value: parseInt(values.byte2, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte3_NV',
        value: parseInt(values.byte3, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte4_NV',
        value: parseInt(values.byte4, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte5_NV',
        value: parseInt(values.byte5, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte6_NV',
        value: parseInt(values.byte6, 16)
      },
      {
        id: 'SafeMode_CAN_CANMSG_Data_Byte7_NV',
        value: parseInt(values.byte7, 16)
      }
    ])
  }

  if (values.enableDigitalInput) {
    machineStateDeterminationParameters = machineStateDeterminationParameters.concat([
      {
        id: 'SafeMode_Pin_Pin_NV',
        value: values.digitalInputPin
      }
    ])
  }

  return machineStateDeterminationParameters
}

const transformMFIOValuesForAPI = values => {
  const mfioParameters = [
    {
      id: 'MFIO0_InputMode_NV',
      value: values.inputSignal0
    },
    {
      id: 'MFIO0_Bias_NV',
      value: values.bias0
    },
    {
      id: 'MFIO0_Range_NV',
      value: values.inputRange0
    },
    {
      id: 'MFIO0_DigTreshL_NV',
      value: values.digThreshLow0
    },
    {
      id: 'MFIO0_DigThresH_NV',
      value: values.digThreshHigh0
    },
    {
      id: 'MFIO1_InputMode_NV',
      value: values.inputSignal1
    },
    {
      id: 'MFIO1_Bias_NV',
      value: values.bias1
    },
    {
      id: 'MFIO1_Range_NV',
      value: values.inputRange1
    },
    {
      id: 'MFIO1_DigTreshL_NV',
      value: values.digThreshLow1
    },
    {
      id: 'MFIO1_DigThresH_NV',
      value: values.digThreshHigh1
    },
    {
      id: 'MFIO2_InputMode_NV',
      value: values.inputSignal2
    },
    {
      id: 'MFIO2_Bias_NV',
      value: values.bias2
    },
    {
      id: 'MFIO2_Range_NV',
      value: values.inputRange2
    },
    {
      id: 'MFIO2_DigTreshL_NV',
      value: values.digThreshLow2
    },
    {
      id: 'MFIO2_DigThresH_NV',
      value: values.digThreshHigh2
    },
    {
      id: 'MFIO3_InputMode_NV',
      value: values.inputSignal3
    },
    {
      id: 'MFIO3_Bias_NV',
      value: values.bias3
    },
    {
      id: 'MFIO3_Range_NV',
      value: values.inputRange3
    },
    {
      id: 'MFIO3_DigTreshL_NV',
      value: values.digThreshLow3
    },
    {
      id: 'MFIO3_DigThresH_NV',
      value: values.digThreshHigh3
    }
  ]

  return mfioParameters
}

export const transformValuesForAPI = (values, isLegacyVersion = true) => {
  let parameters = [
    {
      id: 'CAN0_Node_NV',
      value: values.nodeId0
    },
    {
      id: 'CAN1_Node_NV',
      value: values.nodeId1
    },
    {
      id: 'CAN0_Baudrate_NV',
      value: values.baudRate0
    },
    {
      id: 'CAN1_Baudrate_NV',
      value: values.baudRate1
    },
    {
      id: 'CAN0_SA_NV',
      value: values.sa0 // J1939 messages are only received when comming from the here defined source address if the source address in the virtual signal is set to 254 Range : 0 - 253 255: Ignore SA Filter
    },
    {
      id: 'CAN1_SA_NV',
      value: values.sa1 //new
    },
    {
      id: 'Datalog_0_Period_NV',
      value: values.loggingUploadPeriod0
    },
    {
      id: 'Datalog_1_Period_NV',
      value: values.loggingUploadPeriod1
    },
    {
      id: 'Interlink_Enable_NV',
      value: values.interlinkEnabled
    },
    {
      id: 'FOTA_Enable_NV',
      value: values.fotaUpdateEnabled
    },
    {
      id: 'RestartCellularModule_NV',
      value: values.restartCellularModule
    },
    {
      id: 'GNSS_Config_PositionLogTime_NV',
      value: values.gnssPositionEnabled ? values.positionLogTime : 4294967295
    },
    {
      id: 'GNSS_Config_AltitudeLogTime_NV',
      value: values.gnssAltitudeEnabled ? values.altitudeLogTime : 4294967295
    },
    {
      id: 'GNSS_Config_HeadingLogTime_NV',
      value: values.gnssHeadingEnabled ? values.headingLogTime : 4294967295
    },
    {
      id: 'GNSS_Config_SpeedLogTime_NV',
      value: values.gnssSpeedEnabled ? values.speedLogTime : 4294967295
    }
  ]

  if (!isLegacyVersion && typeof values.nodeId !== 'undefined') {
    parameters.push({
      id: 'Node_NV',
      value: values.nodeId
    })
  }

  const geofenceParameters = transformGeofenceValuesForAPI(values)
  const mfio = transformMFIOValuesForAPI(values)
  const dm1 = transformDM1ValuesForAPI(values)
  const machineStateDetermination = transformMachineStateDeterminationValuesForAPI(values)
  const sendCANMessages = transformSendCANMessagesValuesForAPI(values.sendCANMessages, isLegacyVersion)
  const signals = transformSignalValuesForAPI(values.signals, isLegacyVersion)
  parameters = parameters.concat(geofenceParameters, mfio, dm1, machineStateDetermination, sendCANMessages, signals)

  return parameters
}

export const transformVirtualParametersValuesForAPI = values => {
  const virtualParameters = []

  const signalIdToSignalConfigBase = signalId => {
    const n = Math.floor(signalId / 10)
    const m = signalId % 10
    return `SignalConfig${n}__${m}_`
  }

  const signalValues = values.signals
  signalValues.forEach(s => {
    const signalConfigBase = signalIdToSignalConfigBase(s.signalId)

    if (s.name) {
      virtualParameters.push({
        id: signalConfigBase + 'SignalSource_NV',
        name: s.name
      })
    }
    if (s.unitType === unitTypeValidValues.CUSTOM_UNIT_TYPE) {
      virtualParameters.push({
        id: signalConfigBase + 'SignalSource_NV',
        unit: s.customUnit ? s.customUnit : ''
      })
    } else {
      virtualParameters.push({
        id: signalConfigBase + 'SignalSource_NV',
        unit: null
      })
    }
  })
  return virtualParameters
}

const returnSign = number => {
  const sign = Math.sign(number)
  if (sign === 0) {
    return 1
  } else if (Object.is(number, -0)) {
    return -1
  } else {
    return sign
  }
}

const decimalToFraction = number => {
  let integerPart, numerator, denominator

  if (number !== '' && !Number.isInteger(parseFloat(number))) {
    const numberParts = number.toString().replace(',', '.').split('.')

    integerPart = parseInt(numberParts[0])
    numerator = parseInt(numberParts[1]) * returnSign(integerPart)
    denominator = Math.pow(10, numberParts[1].length)

    const config = {
      number: 'BigNumber',
      precision: 20
    }
    const math = create(all, config)

    const gdc = math.gcd(numerator, denominator)

    numerator = numerator / gdc
    denominator = denominator / gdc
  } else {
    integerPart = number
    numerator = 0
    denominator = 1
  }
  return { integerPart, numerator, denominator }
}

export const getSignalsWithAdaptedParameters = signals => {
  const config = {
    number: 'BigNumber',
    precision: 20
  }

  const math = create(all, config)

  const newSignals = signals.map(signal => {
    if (signal.signalSource === 2 || signal.signalSource === 3) {
      const multiplierFraction = decimalToFraction(signal.multiplier)
      const offsetFraction = decimalToFraction(signal.offset)

      const newDivider = math.lcm(multiplierFraction.denominator, offsetFraction.denominator)
      const newMultiplier =
        (multiplierFraction.integerPart * multiplierFraction.denominator + multiplierFraction.numerator) *
        (newDivider / multiplierFraction.denominator)
      const newOffset =
        (offsetFraction.integerPart * offsetFraction.denominator + offsetFraction.numerator) *
        (newDivider / offsetFraction.denominator)

      return {
        ...signal,
        multiplier: newMultiplier,
        offset: newOffset,
        divider: newDivider
      }
    } else {
      return signal
    }
  })
  return newSignals
}
